import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import GeneralHeader from '../components/Header/GeneralHeader'
import WhatIsResearch from '../components/WhatIsResearch/WhatIsResearch'
import CareersGrid from '../components/CareersGrid/CareersGrid'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Careers',
    url: '/careers',
  },
]

const Careers = () => {
  return (
    <Layout activeLink="/careers" title="R&D Careers" description="R&D Careers">
      <GeneralHeader breadcrumbData={breadcrumbData} heading="Careers" />
      <WhatIsResearch
        tag="WORKING WITH US"
        heading="At RDA we make everyday interesting"
        body={[
          'The breadth and diversity of our client base ensures there is always a tremendous variety in the briefs we receive. But at the same time, your career at RDA will give you an expert understanding of how Research & Development works. In fact, a day’s work can seldom be more fulfilling than when you are helping to deliver the funds that keep the UK at the forefront of global industry.',
          'Here at RDA you’ll be contributing to the development of the imaginers, the innovators and the iterators who are the beating heart of Great Britain’s sustainable future.Here are some of the positions currently available at RDA:',
        ]}
        maxwidth="893px"
      />
      <CareersGrid
        data={[
          {
            position: 'Accountant',
            required:
              'We are seeking a detail-oriented part-qualified Accountant who will play a vital part in maintaining the financial health of our company by ensuring the accuracy and integrity of our financial records.',
            url: '/accountant.pdf',
            details:
              'Whilst industry knowledge of Tax would be beneficial, this is not essential to succeed in the role.  With our support, you will be able to apply your skills, expertise and desire for knowledge to become a specialist in this field',
          },
          {
            position: 'Client Manager',
            required:
              'The purpose of the Client Manager role is to develop an understanding of R&D Tax Credits Legislation in order to effectively manage and develop client relationships across a portfolio of sectors.',
            url: '/client_manager_new.pdf',
            details:
              'This is a critical role to ensure we maintain our high service levels and effectively manage and develop client relationships across a portfolio of sectors as part of the Research and Development Expenditure Credit (RDEC) R&D tax credit scheme.'
            },
          {
            position: 'Marketing Manager',
            required:
              'We are seeking a talented Marketing Manager to join our growing team. You will play a pivotal role in developing and executing strategic marketing plans to strengthen our brand awareness.',
            url: '/marketing_manager.pdf',
            details:
              'The Marketing Manager role is a critical role having responsibility for developing, implementing and executing our strategic marketing plans for the Company in order to attract potential customers and retain existing ones',
          },
          {
            position: 'Senior Report Writer',
            required:
              'We have an exciting opportunity for a highly self-motivated and enthusiastic candidate to join our Report Writing Team, as we continue to accelerate our considerable growth.',

            url: '/Report_Writer.pdf',
            details:
              'The Senior Report Writer will provide comprehensive and professionally written content, presenting detailed technical and specialist information in a form that can be easily interpreted by a wider, nontechnical audience.',
          },
        ]}
      />

      <CallBackBanner />
    </Layout>
  )
}

export default Careers
