import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import linkedin from '../../images/social-media/linkedin.svg'
import MainGrid from '../styling/MainGrid'
import VerticalPadding from '../styling/VerticalPadding'
import Button from '../shared/Button/Button'
import { scrollTo } from '../../util/functions'
import InlineLink from '../shared/InlineLink'

interface CardProps {
  position: string
  required: string
  details: string
  url: string
}
interface CareersGridProps {
  data: Array<CardProps>
}
const CareersGrid: FunctionComponent<CareersGridProps> = ({ data }) => {
  const [charLimit, setCharLimit] = React.useState(300)

  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    }
    return text.substr(0, charLimit) + '...'
  }

  return (
    <Container>
      <MainGrid>
        <VerticalPadding>
          <Grid>
            {data &&
              data.map((item, key) => {
                return (
                  <Card key={key}>
                    <Position>{item.position}</Position>

                    <RequiredHeading className="p-large">
                      Required experience
                    </RequiredHeading>
                    {item.required && (
                      <Required className="p-large">
                        {formatText(item.required)}
                      </Required>
                    )}
                    <RequiredHeading className="p-large">
                      Description
                    </RequiredHeading>
                    {item.details && (
                      <Required className="p-large" marginBottom="40px">
                        {formatText(item.details)}
                      </Required>
                    )}
                    <LinkWrapper>
                      {item.url && (
                        <>
                          <InlineLink
                            to={
                              (process.env.BASE === '/'
                                ? ''
                                : process.env.BASE) + item.url
                            }
                            text={'Find out more'}
                            icon
                            target="_blank"
                          />

                          
                        </>
                      )}
                    </LinkWrapper>
                    <a href="mailto:info@rda.co.uk" target="_blank" style={{fontSize:'18px', fontWeight:'300', color: 'rgb(11, 142, 54)' }}>Apply here</a>
                  </Card>
                )
              })}
          </Grid>
        </VerticalPadding>
      </MainGrid>
    </Container>
  )
}

export default CareersGrid

const Container = styled.section``

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    margin: 0px 0 63px;
  }
`
interface CardProps {
  hideMobile?: boolean
}

const Card = styled.div<CardProps>`
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  padding: 27px 33px 40px;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    padding: 58px 40px 65px;
  }
`

const Position = styled.h6`
  font-weight: bold;
  margin-bottom: 20px;
  @media (min-width: 1024px) {
    margin-bottom: 30px;
  }
`

const RequiredHeading = styled.p`
  font-weight: bold;
`
interface RequiredProps {
  marginBottom?: string
}
const Required = styled.p<RequiredProps>`
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '16px')};
`
const LinkWrapper = styled.div`
  @media (min-width: 768px) {
    margin-top: auto;
  }
`
